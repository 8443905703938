import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import './assets/css/bootstrap.css';
import './assets/css/bootstrap.min.css';
import './assets/css/icons.css';
import './assets/css/pace.min.css';
import './assets/css/sidebar-menu.css';
import './assets/css/animate.css';
import './assets/css/app-style.css';
import './core/i18n/config.ts';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

const AuthenticatedRoutes = lazy(() => import('./routes/Authenticated.tsx'));
const UnAuthenticatedRoutes = lazy(() => import('./routes/UnAuthenticated.tsx'));

const SiteLayout = lazy(() => import('./core/Layout/index.tsx'));
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
// const isAuthenticated = () => Object.keys(JSON.parse(user)).length !== 0;
let user = localStorage.getItem('user');
let gotoLogin = true;
if(user!==null){
  user = JSON.parse(user);
  if(typeof user.localLoginAt!=="undefined"){
    let now = new Date();
    let old = new Date(user.localLoginAt);
    if(typeof user.loginAt==="undefined"){ user.loginAt=user.localLoginAt; }
    if(Math.abs(now-old)/(60*60*1000)<1){
      gotoLogin = false;
      user.localLoginAt = new Date();
      localStorage.removeItem('user');
      localStorage.setItem('user', JSON.stringify(user));
    }

    if(user.isTestDrive===1){
      let lIn = new Date(user.loginAt);
      let exp = new Date(lIn.getTime()+(user.expiredAt*(60*1000)));
      if(exp<now){ gotoLogin = true; }
    }
  }
}

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        {user !==null && gotoLogin===false? 
          <SiteLayout>
            <AuthenticatedRoutes /> 
          </SiteLayout>
          : 
          <UnAuthenticatedRoutes />
        }
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
