
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import FrListing from '../../assets/locales/fr/Listing.json';
import FrMenus from '../../assets/locales/fr/Menus.json';
import EnMenus from '../../assets/locales/en/Menus.json';
import EnListing from '../../assets/locales/en/Listing.json';
import EnImport from '../../assets/locales/en/Import.json';
import FrImport from '../../assets/locales/fr/Import.json';
import EnStatements from '../../assets/locales/en/Statements.json';
import FrStatements from '../../assets/locales/fr/Statements.json';
import EnImages from '../../assets/locales/en/Images.json';
import FrImages from '../../assets/locales/fr/Images.json';
import EnCalendar from '../../assets/locales/en/Calendar.json';
import FrCalendar from '../../assets/locales/fr/Calendar.json';
import EnLogs from '../../assets/locales/en/Logs.json';
import FrLogs from '../../assets/locales/fr/Logs.json';
import EnBuyerProfiles from '../../assets/locales/en/BuyerProfiles.json';
import FrBuyerProfiles from '../../assets/locales/fr/BuyerProfiles.json';
import EnSignUp from '../../assets/locales/en/SignUp.json';
import FrSignUp from '../../assets/locales/fr/SignUp.json';

import ENAdminPanelAIConfig from "../../assets/locales/admin_panel/en/AI_Config.json";
import FRAdminPanelAIConfig from "../../assets/locales/admin_panel/fr/AI_Config.json";

export const defaultNS = 'main';
export const resources = {
  en: {
    Listing: EnListing,
    Menus: EnMenus,
    Import: EnImport,
    Statements: EnStatements,
    Images: EnImages,
    Calendar: EnCalendar,
    Logs: EnLogs,
    BuyerProfiles: EnBuyerProfiles,
    SignUp: EnSignUp,

    AIConfig: ENAdminPanelAIConfig
  },
  fr: {
    Listing: FrListing,
    Menus: FrMenus,
    Import: FrImport,
    Statements: FrStatements,
    Images: FrImages,
    Calendar: FrCalendar,
    Logs: FrLogs,
    BuyerProfiles: FrBuyerProfiles,
    SignUp: FrSignUp,

    AIConfig: FRAdminPanelAIConfig
  }
};

i18n
  .use(initReactI18next)
  .init({ lng: localStorage.getItem('lang') || 'en', defaultNS: [defaultNS], resources });

export default i18n;